import request from '@/api/request'

//领取活动优惠券
export function receiveCouponActivity(){
    return request({
        url: '/member/member-coupon/receiveActivity',
        method: 'post'
    });
}
// 校验活动商品
export function checkActivityGoods(prizeId) {
    return request({
        url: '/member/activity/nami/get/verify',
        method: 'get',
        params: {
            prizeId
        }
    });
}
// 提交领取
export function receiveGoods(data) {
    return request({
        url: '/member/activity/nami/get',
        method: 'post',
        data,
        params: {
            regionId: data.regionId
        }
    });
}
// 获取预存活动参与用户列表
export function getPreStorageActivityUserList(activityId, prepaidApplyBillId) {
    return request({
        url: '/member/prepaid-activity-apply/applyList',
        params: {
            activityId, prepaidApplyBillId
        }
    });
}
// 获取正在进行的临时活动
export function getOnlineTempActivity() {
    return request({
        url: '/activity/test-under/activity',
    });
}
// 获取下单有礼兑换商品
export function getPlaceOrderGoodsList() {
    return request({
        url: '/activity/test-under/goods',

    });
}
// 获取下单有礼用户有效订单
export function getUserPlaceOrderList() {
    return request({
        url: '/activity/test-under/wait/orderList',
    });
}
// 用户兑换商品
export function convertGoods(data) {
    return request({
        url: '/activity/test-under/convert',
        method: 'post',
        data
    });
}
// 获取用户兑换礼品记录
export function getUserConvertRecords(params) {
    return request({
        url: '/member/activity-address/queryPage',
        method: 'get',
        params
    });
}
// 毕业季活动
export function PostGraduationProject(data) {
    return request({
        url: '/activity/graduation-project',
        method: "post",
        data
    });
}

// 修改作品
export function PutGraduationProject(data) {
    return request({
        url: '/activity/graduation-project',
        method: "put",
        data
    });
}
// 作品分页查询
export function getGraduationProjectQueryPage(params) {
    return request({
        url: '/activity/graduation-project/queryPage',
        method: "get",
        params
    });
}
// 投票
export function graduationProjectVote(data) {
    return request({
        url: `/activity/graduation-project/vote/${data.activityId}/${data.id}`,
        method: "post",

    });
}
// id查询作品详情
export function getGraduationProjectDetail(id) {
    return request({
        url: `/activity/graduation-project/${id}`,
    });
}
// 用户删除作品
export function deleteGraduationProjectWork(id) {
    return request({
        url: `/activity/graduation-project/${id}`,
        method: 'delete'
    });
}
// 获取毕业季活动
export function getGraduationProjectActivity() {
    return request({
        url: `/activity/graduation-project/getActivity`,
    });
}
// 毕业项目收藏
export function graduationCollect(id) {
    return request({
        url: `/activity/graduation-collect/${id}`,
        method: "post"
    });
}
// 毕业项目取消收藏
export function cancelGraduationCollect(id) {
    return request({
        url: `/activity/graduation-collect/${id}`,
        method: "delete"
    });
}
// 获取用户自己的作品
export function getGraduationProjectWorkByMy() {
    return request({
        url: `/activity/graduation-project/getByUser`,
    });
}
// 领取实物奖励
export function getGraduationProjectAward(data) {
    return request({
        url: `/activity/graduation-project/give`,
        method: "put",
        data
    });
}
// 邀新奖励
// 获取邀新活动
export function getInviteActivity() {
    return request({
        url: `/activity/member-invite/activity`,
    });
}
// 获取邀新用户统计
export function getInviteUserCount() {
    return request({
        url: `/activity/member-invite/inviteCount`,
    });
}
// 获取邀新二维码
export function getInviteQrCode() {
    return request({
        url: `/activity/member-invite/inviteCode`,
    });
}
// 获取邀新字符串码
export function getInviteStringCode() {
    return request({
        url: `/activity/member-invite/inviteCodeString`,
    });
}
// 获取用户的邀请奖励列表
export function getInviteUserAwardList(params) {
    return request({
        url: `/activity/member-invite/award/queryPage`,
        params
    });
}
// 获取用户邀请的用户列表
export function getInviteUserInviteList(params) {
    return request({
        url: `/activity/member-invite/queryPage`,
        params
    });
}
// 被邀人获取邀请人用户信息
export function getInviteUserInfo(inviteCode) {
    return request({
        url: `/activity/member-invite/getMemberInfo/${inviteCode}`,
    });


}
