<template>
  <div id="app">
    <!-- <nav>
    </nav> -->
    <router-view />
    <!-- 广告弹窗 -->
    <el-dialog
      :lock="true"
      class="popup_dialog"
      :visible.sync="dialogVisible"
      width="680px"
      :close-on-click-modal="false"
      append-to-body
      :close-on-press-escape="false"
    >
      <!-- 其他广告 -->
      <img
        class="popup_img"
        :src="activeDialog?.dialogImgSrc"
        alt=""
        @click="toView"
        v-if="activeDialog.key != 'BIRTHDAY'"
      />
      <!-- 生日送券 -->
      <div class="birthday_box" @click="toView" v-else>
        <!-- 背景图 -->
        <div class="bg">
          <img :src="`${baseUrl}/static/popupImg/birthday.png`" alt="" />
          <div class="text" v-html="msg"></div>
        </div>

        <div class="balloon" ref="balloon">
          <img :src="`${baseUrl}/static/popupImg/balloon.png`" alt="" />
        </div>
      </div>
      <div class="close_dialog" @click="closeDialog">
        <img src="@/assets/imgs/closeIcon.png" alt="" />
      </div>
    </el-dialog>

    <!-- 引导信息弹窗 -->
    <infoDialog
      :options="options"
      :visible="infoDialogVisible"
      @closeDialog="closeInfoDialog"
    ></infoDialog>

    <!--  引导注册-->
    <el-dialog
      :lock="true"
      class="popup_dialog"
      :visible.sync="registerDialogVisible"
      width="600px"
      append-to-body
    >
      <div class="content_box">
        <img
          style="cursor: pointer; width: 515px"
          @click="toLogin"
          :src="`${baseUrl}/static/popupImg/register-popup.png`"
          alt=""
        />
        <div class="close_dialog" @click="closeCouponDialog">
          <img src="@/assets/imgs/closeIcon.png" alt="" />
        </div>
      </div>
    </el-dialog>

<!--    活动领券-->
    <el-dialog
        :lock="true"
        class="popup_dialog"
        :visible.sync="couponActivityDialogVisible"
        width="600px"
        append-to-body
    >
      <div class="content_box">
        <div style="position: relative;">
          <img
              style="cursor: pointer; width: 515px"
              @click="receiveActivityCoupon"
              :src="`${baseUrl}/static/popupImg/activityCoupon.png`"
              alt=""

          />
          <p style="position: absolute;  bottom: 0; left: 50%;transform: translateX(-50%);padding: 5px;">活动时间：2月10日-2月16日</p>
        </div>

        <div class="close_dialog" @click="closeCouponDialog()">
          <img src="@/assets/imgs/closeIcon.png" alt="" />
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getTime, getHashSearchParam } from "@/tool";
import { getPopup } from "@/api/home";
import { mapState, mapActions } from "vuex";
import { checkLogin } from "@/tool";
import infoDialog from "@/components/dialogComponents/infoDialog.vue";
import {receiveCouponActivity} from "@/api/activity";
import {Message} from "element-ui";
export default {
  components: { infoDialog },
  data() {
    return {
      dialogVisible: false,
      timeInterval: null,
      infoDialogVisible: false,
      registerDialogVisible: false,
      couponActivityDialogVisible: false,
      timer: null,
      options: {
        title: "完善个人信息",
        content: `<p>尊敬的用户：<p> 您好！您的个人信息填写不完整，为了保证您在平台能够正常使用，请前往个人中心去完善个人信息。`,
        btnText: "马上完善",
        path: "/myCenter/personalData",
      },
      // activeDialog: {
      //   key: "BIRTHDAY",
      //   dialogImgSrc: `${this.baseUrl}/static/popupImg/birthday.png`,
      //   dialogPath: "/myCenter/personalData",
      // },
      msg: "",
      activeDialog: {},
      popupList: [],
      blackList: ["/Register", "/Login"],
      dialogList: [
        {
          key: "VIP",
          dialogImgSrc: `${this.baseUrl}/static/popupImg/vip-popup.png`,
          dialogPath: "/openPayment",
        },
        // {
        //   key: "NEW_MEMBER",
        //   dialogImgSrc: `${this.baseUrl}/static/popupImg/new-people.png`,
        //   dialogPath: "/myCenter/coupon",
        // },
        {
          key: "BIRTHDAY",
          dialogPath: "/",
        },
      ],
    };
  },
  created() {
    this.init();
    this.timer = setInterval(() => {
      if (checkLogin()) {
        this.showPopup();
      }
    }, 10000);

    // classroomData.forEach((i, index) => {
    //   setTimeout(() => {
    //     var video = document.createElement("video");
    //     // 设置视频源URL
    //     video.src = i.url;
    //     // 监听视频元数据加载事件
    //     video.addEventListener("loadedmetadata", function () {
    //       // 视频时长（单位：秒）
    //       let duration = video.duration;
    //       i.duration = Math.trunc(duration);
    //       console.log(i.duration, "scends");
    //     });
    //   }, index + 1 * 2000);
    //   setInterval(() => {
    //     console.log(classroomData, "end");
    //   }, 60000);
    // });
  },

  computed: {
    ...mapState("user", ["userInfo"]),
  },
  mounted() {
    this.$EventBus.$on("getUserInfo", () => {
      this.getUserInfo();
    });
  },
  methods: {
    ...mapActions("home", ["getCategory"]),
    ...mapActions("user", ["storeGetUserInfo", "SET_TOKEN"]),

    initTime(){
      // 获取当前日期
      var currentDate = new Date();

      // 设置开始日期为2025年2月10日
      var startDate = new Date(2025, 1, 10); // 1代表二月，月份从0开始计数

      // 设置结束日期为2025年2月16日
      var endDate = new Date(2025, 1, 16);

      // 判断当前日期是否在指定范围内
      if (currentDate >= startDate && currentDate <= endDate) {
        if (this.$route.path != "/Login"&&!localStorage.getItem("receiveCouponActivity20250210")) {
          this.couponActivityDialogVisible = true;
        }
        this.registerDialogVisible = false;
      } else {
        this.couponActivityDialogVisible = false;
        if (this.$route.path != "/Login") {
          this.registerDialogVisible = true;
        }
      }
    },

    init() {
      // 获取时间
      getTime();
      // 判断是否登录
      if (checkLogin()) {
        this.getUserInfo();
      } else {
        this.initUrlToken();
      }

      // 获取广告
      // this.getPopup();
      // 获取测试分类
      this.getCategory();
      this.initTime();
      if (!localStorage.getItem("receiveCouponActivity20250210")){
        this.timeInterval = setInterval(() => {
          this.initTime();
        }, 3000);
      }
    },
    initUrlToken() {
      // const query = this.$route.query;
      location.hash;
      let obj = {
        access_token: getHashSearchParam("access_token"),
        refresh_token: getHashSearchParam("refresh_token"),
        uid: getHashSearchParam("uid"),
        uuid: getHashSearchParam("uuid"),
      };
      if (obj.refresh_token) {
        this.SET_TOKEN(obj);
      } else {
        // 没有登录  展示引导登录
        // if (this.$route.path != "/Login") {
        //   this.registerDialogVisible = true;
        //   this.couponActivityDialogVisible = true;
        // }
      }
    },
    getUserInfo() {
      this.storeGetUserInfo();
    },
    receiveActivityCoupon(){
      if (!checkLogin()) {
        this.toLogin();
      }else {
        //领取优惠券
        receiveCouponActivity().then(()=>{
          Message.success("领取成功");
        }).finally(()=>{
          localStorage.setItem("receiveCouponActivity20250210",true);
          this.closeCouponDialog()
        })
      }
    },
    toView() {
      // 跳转页面
      this.$router.push(this.activeDialog.dialogPath);
      this.closeDialog();
    },
    showPopup() {
      getPopup().then((res) => {
        if (res.length) {
          this.popupList = res;
          let pushMessageCache = localStorage.getItem("push_message");
          if (pushMessageCache) {
            this.popupList = this.popupList.concat(
              JSON.parse(pushMessageCache)
            );
          }
          localStorage.setItem("push_message", JSON.stringify(this.popupList));
        } else {
          this.popupList = localStorage.getItem("push_message")
            ? JSON.parse(localStorage.getItem("push_message"))
            : [];
        }

        this.showDialog();
      });
    },
    // 获取有没有广告弹出
    getPopup() {
      getPopup().then((res) => {
        if (res) {
          this.popupList = res;
          this.showDialog();
        }
      });
    },
    closeInfoDialog() {
      this.infoDialogVisible = false;
    },
    closeCouponDialog(){
      this.couponActivityDialogVisible = false;
      this.registerDialogVisible = false;
      clearInterval(this.timeInterval);
    },
    closeDialog() {
      this.dialogVisible = false;
      let index = this.popupList.findIndex(
        (i) => i.messageType == this.activeDialog.key
      );
      // console.log(this.popupList, this.activeDialog);
      // console.log(index, "index");
      if (index != -1) {
        this.popupList.splice(index, 1);
        localStorage.setItem("push_message", JSON.stringify(this.popupList));
        this.showDialog();
      }
    },
    toLogin() {
      this.$router.push("/Login");
      this.registerDialogVisible = false;
      this.couponActivityDialogVisible = false;
    },
    // 尝试显示弹窗
    showDialog() {
      if (!this.blackList.includes(this.$route.path) && this.popupList.length) {
        // console.log(this.popupList);
        this.popupList.forEach((i) => {
          this.dialogList.forEach((it) => {
            if (i.messageType == it.key) {
              this.activeDialog = { ...it };
              this.dialogVisible = true;
              this.msg = i.message;
            }
          });
        });
        this.$nextTick(() => {
          if (this.activeDialog.key == "BIRTHDAY") {
            let dom = this.$refs.balloon;
            if (dom) {
              dom.style.animation = "balloon 1.5s linear";
            }
          }
          if (this.activeDialog.key == "VIP") {
            this.getUserInfo();
          }
        });
      }
    },
  },
};
</script>
<style lang="less">
// @import url("@/assets/css/base.less");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position: relative;
}
.el-popconfirm__main {
  margin-bottom: 12px;
}
.consult_popover {
  .content {
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 170px;
      height: 170px;
    }

    .text {
      display: flex;
      font-weight: 700;
      color: #2f2f2f;
      justify-content: center;
      .left {
        font-size: 20px;
      }
      .right {
        font-size: 22px;
      }
    }
  }
}

.popup_dialog {
  .el-dialog__header {
    display: none;
  }
  .content_box {
    position: relative;
    & > img {
    }
    .close {
      position: absolute;
      right: 20px;
      top: 0;
    }
  }
  .el-dialog {
    background: rgba(0, 0, 0, 0) !important;
    position: relative;
    box-shadow: none;
    max-height: 520px;
  }
  .close_dialog {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: rgba(113, 113, 113, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -24px;
    top: -30px;
    cursor: pointer;
  }
  .el-dialog__body {
    padding: 0;
    background: rgba(0, 0, 0, 0) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 900px;
    .popup_img {
      max-width: 100%;

      cursor: pointer;
    }
  }
}

.birthday_box {
  width: 516px;
  height: 498px;
  position: relative;
  cursor: pointer;
  .bg {
    img {
      max-width: 516px;
    }
    .text {
      width: 320px;
      position: absolute;
      top: 100px;
      left: 100px;
    }
  }

  .balloon {
    position: absolute;
    top: -39px;
    right: 0px;
    // top: 600px;
  }
}
</style>
